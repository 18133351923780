import { render, staticRenderFns } from "./Talks.vue?vue&type=template&id=79d2dd9f&scoped=true&"
import script from "./Talks.vue?vue&type=script&lang=js&"
export * from "./Talks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d2dd9f",
  null
  
)

export default component.exports