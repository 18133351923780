<template>
  <div>
    <b-sidebar
      ref="sb"
      v-model="state"
      v-resize="onResize"
      :width="`${width}px`"
      right
      shadow
      no-close-on-esc
      no-close-on-backdrop
    >
      <div v-if="state" class="bg-white px-1 px-lg-3">
        <data-layout
          v-if="userId"
          ref="dt"
          class="flex-grow-1 text-nowrap"
          :fields="fields"
          :filter-fields="filterFields"
          :filter="filter"
          :order="order"
          repo="customers.talks"
          :parent-id="userId"
          :height="height"
          :limit="50"
          card-collapse
          :set-item-variant="item => item.deleted_at !== null ? 'table-danger' : ''"
          @on-filter-submit="onFilterSubmit"
          @on-sort="onSort"
        >
          <template #card(header)="{ data: { item }}">
            <div v-if="item" class="text-wrap">
              <user-profile-link
                v-if="item.user_a"
                :user="item.user_a"
                :name-variant="(item.status_a === 'blocked') ? 'danger' : undefined"
              />
              <span class="text-muted small">
                ({{ item.status_a }})
              </span>
              <font-awesome-icon icon="arrow-right" size="sm" />
              <user-profile-link
                v-if="item.user_b"
                :user="item.user_b"
                :name-variant="(item.status_b === 'blocked') ? 'danger' : undefined"
              />
              <span class="text-muted small">
                ({{ item.status_b }})
              </span>
            </div>
            <div v-if="item" class="small">
              <span class="text-muted">{{ $dateFormat(item.created_at) }}</span>
            </div>
            <div v-if="item" class="small">
              <b-badge
                v-if="item.transaction_id"
                variant="success"
                class="mr-1"
              >
                {{ $t('pTalk_f_unlocked') }}
              </b-badge>
              <b-badge
                v-if="item.has_feedbacks === true"
                variant="success"
                class="mr-1"
              >
                {{ $t('pTalk_feedback_enabled') }}
              </b-badge>
              <b-badge
                variant="info"
                class="mr-1"
              >
                <font-awesome-icon icon="comments" />
                {{ item.messages_count }}
              </b-badge>
              <b-badge
                variant="warning"
                class="mr-1"
              >
                <font-awesome-icon icon="coins" />
                {{ item.messages_count }}
              </b-badge>
            </div>
          </template>
          <template #card(actions)="{ data: { item } }">
            <b-dropdown-item-button
              v-if="item"
              variant="info"
              size="sm"
              @click="onRead(item.id)"
            >
              <font-awesome-icon icon="book-open" size="sm" class="mr-2" />
              {{ $t('pTalk_readMessages') }}
            </b-dropdown-item-button>
          </template>
          <template #cell(user_a)="{ data: { item } }">
            <user-profile-link
              v-if="item && item.user_a"
              :user="item.user_a"
              :name-variant="(item.status_a === 'blocked') ? 'danger' : undefined"
            />
          </template>
          <template #cell(user_b)="{ data: { item } }">
            <user-profile-link
              v-if="item && item.user_b"
              :user="item.user_b"
              :name-variant="(item.status_b === 'blocked') ? 'danger' : undefined"
            />
          </template>
          <template #cell(status_a)="{ data: { item } }">
            <b-badge
              v-if="item && item.status_a === 'blocked'"
              v-shtml="item.status_a"
              variant="danger"
            />
            <span v-else-if="item && item.status_a" v-shtml="item.status_a" />
          </template>
          <template #cell(status_b)="{ data: { item } }">
            <b-badge
              v-if="item && item.status_b === 'blocked'"
              v-shtml="item.status_b"
              variant="danger"
            />
            <span v-else-if="item && item.status_b" v-shtml="item.status_b" />
          </template>
          <template #cell(transaction_id)="{ data: { item } }">
            <span
              :class="item && item.transaction_id ? 'text-success' : 'text-danger'"
            >
              <font-awesome-icon
                v-if="item && item.transaction_id"
                :icon="['fas', 'check']"
              />
              <font-awesome-icon
                v-else
                :icon="['fas', 'xmark']"
              />
            </span>
          </template>
          <template #[`cell(transaction_amount)`]="{ data }">
            <span v-shtml="data.field.formatter(data.item.transaction_amount, data.field.key, data.item)" />
          </template>
          <template #cell(actions)="{ data: { item } }">
            <talk-btn :talk="item">
              {{ $t('fCustomer_talks_btnShow') }}
            </talk-btn>
            <b-btn
              v-if="item.refunded_transaction_id === null && item.transaction_amount > 0"
              variant="warning"
              size="sm"
              @click="onRefundTalk(item.id)"
            >
              {{ $t('fCustomer_talks_refund') }}
            </b-btn>
          </template>
          <template #cell(has_feedbacks)="{ data: { item } }">
            <button
              v-if="item.has_feedbacks === true"
              :disabled="true"
              variant="success"
              size="sm"
            >
              {{ $t('fCustomer_talks_feedback_enabled') }}
            </button>
            <b-btn
              v-else
              variant="success"
              size="sm"
              @click="onEnableFeedbacks(item.id)"
            >
              {{ $t('fCustomer_talks_feedback_disabled') }}
            </b-btn>
          </template>
          <template #cell(created_at)="{ data: { item, index, field } }">
            <span
              v-if="item"
              v-shtml="field.formatter(item.created_at, index, item)"
            />
          </template>
          <template #cell(sent)="{ data: { item, index, field } }">
            <span
              v-if="item"
              v-shtml="field.formatter(item.last_message_sent, index, item)"
            />
          </template>
          <template #cell(market)="{ data: { item } }">
            <market-flag
              :market="item.user_a.market"
            />
            <market-flag
              v-if="item.user_a.market !== item.user_b.market"
              :market="item.user_b.market"
              class="ml-1"
            />
          </template>
        </data-layout>
      </div>
      <template #header>
        <b-link class="mr-2 text-info" @click="onCancel">
          <font-awesome-icon v-if="isMobile" :icon="['fas', 'arrow-left']" class="mr-2" />
          {{ parentTitle }}
        </b-link>
        - {{ displayTitle }}
      </template>
      <template #footer>
        <div v-if="!isMobile" class="d-flex bg-light align-items-center px-3 py-2">
          <strong class="mr-auto" />
          <b-btn variant="secondary" size="sm" @click="onCancel">
            {{ $t('eDoc_form_a_close') }}
          </b-btn>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'Talks',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    userId: {
      type: [String, Number],
      required: true
    },
    idx: {
      type: Number,
      default: -1
    },
    parentTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      fields: [
        {
          key: 'id',
          label: this.$t('fCustomer_talks_f_id'),
          sortable: true,
          class: 'col-1',
          thClass: 'text-center'
        },
        {
          key: 'created_at',
          label: this.$t('fCustomer_talks_f_date'),
          sortable: true,
          class: 'col-2 text-left',
          thClass: 'text-center',
          formatter: (value, key, item) => {
            return this.$dateFormat(value, 'DD/MM/YYYY<br>HH:mm')
          }
        },
        {
          key: 'user_a',
          label: this.$t('fCustomer_talks_f_userA'),
          sortable: true,
          class: 'col-1'
        },
        {
          key: 'user_b',
          label: this.$t('fCustomer_talks_f_userB'),
          sortable: true,
          class: 'col-1'
        },
        {
          key: 'status_a',
          label: this.$t('fCustomer_talks_f_statusA'),
          sortable: true,
          class: 'col-1 text-center'
        },
        {
          key: 'status_b',
          label: this.$t('fCustomer_talks_f_statusB'),
          sortable: true,
          class: 'col-1 text-center'
        },
        {
          key: 'sent',
          label: this.$t('fCustomer_talks_f_lastPostDate'),
          sortable: true,
          class: 'col-2 text-left',
          thClass: 'text-center',
          formatter: (value, key, item) => {
            return item.last_message_sent ? this.$dateFormat(item.last_message_sent, 'DD/MM/YYYY<br>HH:mm') : null
          }
        },
        {
          key: 'total_messages',
          label: this.$t('fCustomer_talks_f_totMessages'),
          sortable: true,
          class: 'col-2 text-center',
          thClass: 'text-center',
          formatter: (value, key, item) => {
            return item.messages_count
          }
        },
        {
          key: 'transaction_id',
          label: this.$t('fCustomer_talks_f_unlocked'),
          sortable: true,
          class: 'col-2 text-center',
          thClass: 'text-center'
        },
        {
          key: 'transaction_amount',
          label: this.$t('fCustomer_talks_f_cost'),
          sortable: true,
          class: 'col-2 text-right',
          thClass: 'text-center',
          formatter: (value, key, item) => {
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'EUR'
            })
            const displayValue = item.transaction_id
              ? formatter.format(item.transaction_amount < 0 ? item.transaction_amount * -1 : item.transaction_amount)
              : formatter.format(0)
            const cls = item.transaction_id ? 'font-weight-bold' : 'text-muted text-sm'
            return `<span class="${cls}">${displayValue}</span>`
          }
        },
        {
          key: 'market',
          label: this.$t('fCustomer_talks_f_market'),
          class: 'col-1 text-center',
          thClass: 'text-center'
        },
        {
          key: 'has_feedbacks',
          label: this.$t('fCustomer_talks_f_has_feedbacks'),
          class: 'col-1',
          thClass: 'text-center',
          sortable: false
        },
        {
          key: 'user_a_feedbacks',
          label: this.$t('fCustomer_talks_f_user_a_feedbacks'),
          class: 'col-1',
          thClass: 'text-center',
          tdClass: 'text-wrap',
          sortable: false,
          formatter: (value, key, item) => {
            return this.feedbackFormatter(item?.user_a_feedbacks || [], item?.user_a?.display_name)
          }
        },
        {
          key: 'user_b_feedbacks',
          label: this.$t('fCustomer_talks_f_user_b_feedbacks'),
          class: 'col-1',
          thClass: 'text-center',
          tdClass: 'text-wrap',
          sortable: false,
          formatter: (value, key, item) => {
            return this.feedbackFormatter(item?.user_b_feedbacks || [], item?.user_b?.display_name)
          }
        },
        {
          key: 'actions',
          label: this.$t('fCustomer_talks_f_actions'),
          class: 'col-2 text-center',
          thClass: 'text-center',
          formatter: (value, key, item) => {
            return value
          }
        }
      ],
      order: {
        by: 'created_at',
        dir: 'desc'
      },
      filterState: false,
      filter: [],
      state: this.value,
      displayTitle: this.$t('fCustomer_talks_title'),
      height: 500,
      width: 300,
      key: 0
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      deviceInfo: 'device',
      tableLayout: 'tableLayout'
    }),
    filterFields () {
      return [
        {
          label: this.$t('pTalk_s_userA'),
          key: 'user_a',
          type: 'input',
          size: '2'
        },
        {
          label: this.$t('pTalk_s_userB'),
          key: 'user_b',
          type: 'input',
          size: '2'
        },
        {
          label: this.$t('pTalk_s_createdAt'),
          key: 'created_at',
          type: 'date-range',
          size: '4'
        },
        {
          label: this.$t('pTalk_s_lastPostDate'),
          key: 'last_message_sent',
          type: 'date-range',
          size: '4'
        }
      ]
    },
    filterData () {
      return this.getByName(`${this.repo}/filterData`)
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    }
  },
  watch: {
    value (n) {
      this.state = n
      this.$nextTick(() => {
        this.calcHeight()
        this.calcWidth()
        // console.log('width:', this.width)
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.calcHeight()
      this.calcWidth()
    })
  },
  beforeDestroy () {},
  methods: {
    ...mapActions({
      enableFeedback: 'talk/enableFeedback',
      refundTalk: 'talk/refundTalk'
    }),
    onSort (order) {
      this.order = order
    },
    onFilterSubmit (filter) {
      this.filter = filter
      this.$nextTick(() => {
        this.$refs.dt.$fetch()
      })
    },
    toggleFilter () {
      this.filterState = !this.filterState
    },
    onCancel () {
      this.$emit('input', false)
    },
    calcHeight () {
      if (!isServer && window) {
        this.$nextTick(() => {
          const $sidebar = this.$refs.sb?.$el?.querySelector('.b-sidebar-body')
          const $pager = this.$refs.sb?.$el?.querySelector('.pager')
          const sidebarHeight = $sidebar?.clientHeight || 0
          const pagerHeight = $pager?.clientHeight || 0
          // calc
          if (sidebarHeight > 0) {
            this.height = sidebarHeight - pagerHeight
          }
        })
      }
    },
    calcWidth () {
      let width = 0
      if (!isServer) {
        if (this.deviceInfo?.type === 'mobile') {
          width = document.body.offsetWidth
        } else {
          width = Math.round(document.body.offsetWidth * 0.80) - (15 * (this.idx + 1))
        }
      }
      this.width = width
    },
    onResize () {
      this.calcHeight()
      this.calcWidth()
    },
    feedbackFormatter (items, userName) {
      const out = []
      items.forEach((f) => {
        if (f.value > 0) {
          let valueName = ''
          switch (f.value) {
            case 1:
              valueName = this.$t('fCustomer_talks_feedback_v_negative')
              break
            case 3:
              valueName = this.$t('fCustomer_talks_feedback_v_neutral')
              break
            case 5:
              valueName = this.$t('fCustomer_talks_feedback_v_positive')
              break
          }
          out.push(this.$t('fCustomer_talks_feedback_left', { user: userName, name: valueName, date: f.updated_at }))
        } else {
          out.push(this.$t('fCustomer_talks_feedback_pending', { user: userName }))
        }
      })
      return out.join('<br/>')
    },
    onEnableFeedbacks (id) {
      this.$confirmWarning({
        title: this.$t('fCustomer_talks_feedback_confirmTitle'),
        body: this.$t('fCustomer_talks_feedback_confirmBody', { id }),
        okTitle: this.$t('fCustomer_talks_feedback_confirmOk'),
        cancelTitle: this.$t('fCustomer_talks_feedback_confirmCancel')
      })
        .then(async (value) => {
          if (value) {
            await this.enableFeedback(id)
            this.$nextTick(() => {
              this.$refs.dt?.$fetch()
            })
          }
        })
    },
    onRefundTalk (id) {
      this.$confirmWarning({
        title: this.$t('pTalk_refund_confirmTitle'),
        body: this.$t('pTalk_refund_confirmBody', { id }),
        okTitle: this.$t('pTalk_refund_confirmOk'),
        cancelTitle: this.$t('pTalk_refund_confirmCancel')
      })
        .then(async (value) => {
          if (value) {
            await this.refundTalk(id)
            this.$nextTick(() => {
              this.$refs.dt?.$fetch()
            })
          }
        })
    },
    onRead (id) {
      this.$openGlobal('TalkPreviewForm', id, 'talks')
    }
  }
}
</script>

<style scoped>

</style>
